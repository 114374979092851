.tab {
    &__title {
        @include mq($screen-l) {
            border-bottom: $tab__title-border\@medium;
        }

        &:nth-last-of-type(1) {
            &:not([aria-expanded="true"]) {
                margin-bottom: $spacer--third-extra-large;
                @include mq($screen-m) {
                    margin-bottom: 0;
                }
            }
        }

        &--active {
            border-bottom: $tab__title-border;
            @include mq($screen-l) {
                border-bottom: $tab__title-border\@medium;
            }
        }
    }

    &__content {
        font-size: $tab__content-font-size;
    }
}
