.article-item {
    &--single {
        .article-item__content .article-item__slider {
            margin: $spacer--third-extra-large 0;
            padding-top: $spacer--third-extra-large;
            border-top: $border-base;

            .slider {
                &__dots {
                    margin-top: $spacer--extra-large;
                }
            }
        }

        .product-grid-item__details {
            .button {
                margin-bottom: $spacer--medium;
            }
        }

        .article-item__banner {
            img {
                margin: 0 auto;
            }
        }
    }

    &__sub-title {
        color: $gray-dark;
    }

    .thumbnail__image {
        overflow: hidden;

        @include mq($screen-l) {
            max-height: 100px;
        }

        @include mq($screen-xl) {
            max-height: 115px;
        }

        @include mq($screen-xxl) {
            max-height: 150px;
        }
    }
}

.contentmanager-index-content {
    #maincontent { // sass-lint:disable-line no-ids
        @include mq($screen-l) {
            margin-top: 0;
        }
    }
}
