.range-filter {
    &__suffix {
        display: none;
    }

    &__field {
        border: $border-base;
    }
}

.noUi-extended {
    padding-right: 0;
    width: calc(100% - #{$range__handler-size});
    margin-left: auto;
    margin-right: auto;

    .noUi-handle {
        right: -$range__handler-size / 2;
    }
}
