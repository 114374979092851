$footer__background-color                                   : $gray-light;

$footer__link-color                                         : $color-primary;
$footer__links-dropdown-background                          : $gray-light;
$footer__links-dropdown-list-padding\@medium                : 0;
$footer__links-dropdown-list-padding\@large                 : 0;
$footer__links-dropdown-list-padding\@extra-large           : 0;
$footer__links-dropdown-list-margin                         : $spacer--medium 0 $spacer 0;
$footer__links-dropdown-list-border-width                   : 0;
$footer__links-dropdown-list-item-border                    : $border-width-base $border-style-base rgba($border-color-base, 0.2);
$footer__links-dropdown-list-item-width-first-child\@medium : 100%;
$footer__links-label-background                             : $gray-light;
$footer__links-padding\@large                               : 0;

$footer__newsletter-border                                  : 4px solid rgba($gray, 0.2);
$footer__newsletter-border-width                            : 0 0 4px 0;
$footer__newsletter-padding\@large                          : 40px 0 $spacer--extra-large 0;

$footer__scroll-top-icon-fill                               : $gray-light;

$footer__social-list-title-font-size                        : $font-size-base;
$footer__social-list-title-font-size\@medium                : $font-size-large;
$footer__social-handler-margin                              : $spacer 0 $spacer--semi-medium;
$footer__social-handler-margin\@medium                      : $spacer--medium 0;
$footer__social-handler-padding\@large                      : 56px 0 56px $spacer--large;
$footer__bottom-align                                       : flex-start;
$footer__payments-list-margin                               : 0;

$footer__copywrite-padding                                  : $spacer--semi-medium 0;

$footer__bottom-bar-handler-padding--checkout              : $spacer--medium $spacer--semi-medium 0;
$footer__bottom-bar-handler-padding--checkout\@medium      : $spacer--medium $spacer--semi-large 0;
$footer__bottom-bar-handler-padding--checkout\@large       : $spacer--medium 0 0;
$footer__bottom-bar-handler-padding--checkout\@extra-large : $spacer--medium 0 0;
