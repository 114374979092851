.review {
    &--summary {
        width: 100%;
        .rating {
            @include mq($screen-m) {
                margin: $review__rating-margin--summary\@medium;
            }
        }
    }

    &__summary-handle {
        flex-wrap: nowrap;
    }

    &__summary-score {
        color: $gray-dark;
        margin-right: $spacer;
    }

    &__summary-add {
        min-width: $review__summary-add-min-width;

        @include mq($screen-xs) {
            margin-left: $spacer--medium;
        }

        @include mq($screen-m) {
            min-width: 0;
            margin: 0 $spacer--semi-medium 0 0;
        }

        &--secondary {
            margin-left: auto;
        }
    }
}
