$product-view__padding                               : $spacer--semi-medium 0 0;
$product-view__padding\@xl                           : 0 0 $spacer--extra-large;
$product-view__max-width\@large                      : 1104px;
$product-view__price-padding                         : $spacer--semi-medium 0;
$product-view__select-option-height                  : 56px;
$product-view__wrapper-margin\@large                 : 0;
$product-view__column-gap\@medium                    : 488px;
$product-view__column-gap\@large                     : 48px;
$product-view__column-gap\@xl                        : 128px;

$product-view__button-bg                             : $gray-light;

$product-view__options-margin                        : 0;

$product-view__marketing-min-height                  : $spacer--third-extra-large;

// gallery
$product-view__gallery-vertical-max-width            : calc(544px + 16px + 98px);
$product-view__gallery-horizontal-max-width          : 544px;


// details
$product-view__details-width\@large                  : 432px;
$product-view__details-width-and-offset\@medium      : calc(50% - #{$spacer});
$product-view__details-width-and-offset\@large       : calc(100% - calc(#{$product-view__details-width\@large} + 48px));


// brief
$product-view__brief-margin                          : $spacer--large 0 $spacer--second-extra-large $spacer--medium;
$product-view__brief-margin\@medium                  : $spacer--semi-medium 0 0;
$product-view__brief-item-margin                     : 0;

// form
$product-view__form-border-width                     : 0;
$product-view__form-padding                          : 0;
$product-view__form-padding\@medium                  : 0;

// short description
$product-view__short-description-font-size           : $font-size-medium;
$product-view__short-description-line-height         : 2;
$product-view__short-description-heading-font-weight : $font-weight-normal;

// sku
$product-view__sku-display                           : block;

// tabs
$product-view__tabs-title-height                     : $spacer--third-extra-large;
$product-view__tabs-title-padding                    : 0 $spacer;
$product-view__tabs-title-padding\@medium            : 0 $spacer;
$product-view__tabs-title-padding\@large             : 0 $spacer;

// title
$product-view__title-margin                          : 0 0 $spacer;

// qty
$product-view__qty-margin                            : 0 0 $spacer--large;

// wrapper
$product-view__wrapper-max-width                     : 1104px;

// tabs
$product-view__tabs-sticky-label-height              : auto;

// product slider
$product-view__slider-margin                         : 0;

// brand
$product-view__brand-padding                         : 56px 0 32px 0;
$product-view__brand-padding\@medium                 : $spacer--third-extra-large 92px 64px;
$product-view__brand-padding\@large                  : 0 0 32px 0;


// grouped
$product-view__grouped-table-margin                  : 0;
$product-view__grouped-td-name-font-weight           : $font-weight-normal;
$product-view__grouped-qty-field-max-width           : 200px;
$product-view__grouped-th-padding                    : $spacer--medium 0;

$product-view__qty-height                            : 56px;

$product-view__summary-list-item-title-font-weight   : $font-weight-lightmedium;
