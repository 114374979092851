.attachments {
    &__title {
        font-family: $font-family-base;
        font-size: $font-size-base;
        margin: 0 0 $spacer--semi-medium;
    }

    &__icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        transition: $transition-base;
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: underline;
        word-break: break-word;

        &:hover,
        &.focus-visible {
            color: $color-primary;

            .attachments__icon-wrapper {
                background: $red;
            }

            .attachments__icon {
                fill: $gray-lighter;
            }
        }
    }

    &__list {
        margin: 0 0 0 $spacer--small;
    }

    &__item {
        padding: 0;
    }
}
