.teaser {
    &--secondary {
        justify-content: center;

        @include mq($screen-m) {
            flex-flow: row wrap;
        }

        @include mq($screen-l) {
            flex-flow: row-reverse nowrap;
        }

        .teaser__image,
        .teaser__content {
            @include mq($screen-m) {
                width: 100%;
            }

            @include mq($screen-l) {
                width: 50%;
            }
        }
    }
}
