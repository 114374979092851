.banner {
    &__text {
        line-height: $banner__text-line-height;
    }

    &__additional {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        margin: $spacer--semi-medium 0;
        padding: 0 $spacer--medium;
        font-size: $font-size-medium;
        text-align: center;
        color: $white;
        background: $color-primary;

        @include mq($screen-m) {
            font-size: $font-size-large;
        }

        @include mq($screen-l) {
            margin: 0 0 $spacer--semi-medium;
            font-size: $font-size-extra-large;
        }
    }
}
