$product-list-item__border-color                : $gray-light;
$product-list-item__padding\@medium             : $spacer--semi-medium 0 0;

$product-list-item__name-margin                 : 0 0 $spacer;
$product-list-item__name-margin\@medium         : 0 0 $spacer;

$product-list-item__image-width                 : 88px;
$product-list-item__image-width\@medium         : 216px;
$product-list-item__image-width\@xl             : 240px;
$product-list-item__image-margin\@medium        : 0 $spacer 0 0;
$product-list-item__image-margin\@xl            : 0 $spacer--semi-medium 0 0;

$product-list-item__action-margin               : 0 $spacer 0 0;
$product-list-item__main-width\@medium          : 100%;

$product-list-item__details-button-width        : 152px;
$product-list-item__details-button-width\@medium: 104px;
$product-list-item__details-button-width\@xl    : 152px;
