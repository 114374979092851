.badge {
    &--staff-favorite {
        background: $badge__background--staff-favorite;
        color: $badge__color--staff-favorite;
    }
    &--bestseller,
    &--best-seller {
        background: $badge__background--bestseller;
        color: $badge__color--bestseller;
    }
}
