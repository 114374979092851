.product-list-item {
    &__image-link {
        min-width: $product-list-item__image-width;
        max-height: $product-list-item__image-width;

        @include mq($screen-m) {
            flex-basis: $product-list-item__image-width\@medium;
            min-width: $product-list-item__image-width\@medium;
            max-width: $product-list-item__image-width\@medium;
            max-height: 100%;
        }

        @include mq($screen-xl) {
            flex-basis: $product-list-item__image-width\@xl;
            min-width: $product-list-item__image-width\@xl;
            max-width: $product-list-item__image-width\@xl;
        }
    }

    &__actions-inner {
        justify-content: flex-start;

        @include mq($screen-m) {
            justify-content: flex-end;
            align-self: flex-end;
            margin-bottom: $spacer;
        }

        @include mq($screen-xl) {
            margin-bottom: $spacer--semi-medium;
        }
    }

    &__name-link {
        font-weight: $font-weight-lightmedium;
    }

    &__sku {
        display: none;
    }

    &__price {
        margin-bottom: $spacer;
    }

    &__manufacturer {
        margin-bottom: $spacer--extra-small;
    }

    &__main {
        @include mq($screen-m) {
            flex-basis: auto;
        }
    }

    &__badges {
        width: 100%;
        top: 100%;

        @include mq($screen-m) {
            top: $product-list-item__badges-top\@medium;
            width: auto;
        }

        .badge {
            margin-bottom: 0;
            padding: 0 $spacer;
            white-space: nowrap;
            overflow: hidden;
            min-height: $badge__line-height;

            @include mq($screen-m) {
                padding: 0 $spacer--medium;
                margin-bottom: $spacer;
                white-space: initial;
                overflow: initial;
            }
        }
    }

    &__details-button {
        width: $product-list-item__details-button-width;

        @include mq($screen-m) {
            width: $product-list-item__details-button-width\@medium;
        }

        @include mq($screen-xl) {
            width: $product-list-item__details-button-width\@xl;
        }
    }

    .button--wishlist {
        background-color: $gray-light;
    }

    .shipping_latency {
        display: none;
    }
}
