.home {
    &__blog-posts {
        margin: 0 $spacer--semi-medium $spacer--semi-medium;

        @include mq($screen-m) {
            margin: 0 0 $spacer--semi-medium;
        }

        .blog-posts__item-title {
            font-size: $font-size-large;

            @include mq($screen-l) {
                font-size: $font-size-extra-large;
            }
        }

        .blog-posts__item {
            margin-bottom: $spacer--extra-large;

            & > .image {
                overflow: hidden;

                @include mq($screen-m) {
                    max-height: 118px;
                }

                @include mq($screen-l) {
                    max-height: 150px;
                }

                @include mq($screen-xl) {
                    max-height: 178px;
                }

                @include mq($screen-xxl) {
                    max-height: 210px;
                }
            }
        }

        .blog-posts__item-category {
            margin: $spacer--medium 0 $spacer;

            @include mq($screen-xl) {
                margin: $spacer--medium $spacer--semi-medium $spacer;
            }
        }

        .blog-posts__item-title {
            margin: 0;

            @include mq($screen-xl) {
                margin: 0 $spacer--semi-medium;
            }
        }
    }
}
