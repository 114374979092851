.select2-container--default .select2-selection--single {
    .select2-selection__arrow {
        width: 24px;
        b {
            padding: 3.25px;
            margin-left: -5px;
        }
    }
}

//set raw values for toolbar selects to avaid width jump
.select {
    &__field {
        font-family: $font-family-sans-serif;
        color: $gray-dark;

        &.toolbar__sorter-select {
            width: 165px;
        }

        &.toolbar__limiter-select {
            width: 80px;
        }
    }

    &.toolbar__sorter {
        .select2 {
            width: 165px;
        }
    }

    &.toolbar__limiter {
        .select2 {
            width: 80px;
        }
    }
}
