.footer {
    &__links {
        border-bottom: $footer__newsletter-border;
        border-width: $footer__newsletter-border-width;
        margin-bottom: $spacer--medium;

        @include mq($screen-l) {
            border: none;
            margin-bottom: 0;
        }

        .dropdown-list {
            &--with-breakpoint {
                .dropdown-list__icon {
                    @include mq($screen-m) {
                        display: block;
                    }

                    @include mq($screen-l) {
                        display: none;
                    }
                }
            }

            &__label {
                font-size: $font-size-base;

                @include mq($screen-m) {
                    font-size: $font-size-large;
                }

                @include mq($screen-l) {
                    padding: $spacer--small $spacer--extra-large $spacer--small 0;
                    margin-bottom: $spacer;
                }
            }

            &__item {
                @include mq($screen-m) {
                    border-bottom: $footer__links-dropdown-list-item-border;
                }

                @include mq($screen-l) {
                    border: none;
                    width: 20%;
                }

                &:first-of-type {
                    @include mq($screen-l) {
                        width: 20%;
                    }
                }

                &--mobile-link {
                    border-bottom: $footer__links-dropdown-list-item-border;
                    padding: 0;

                    @include mq($screen-l) {
                        border-bottom: none;
                        padding: $spacer--small 0;
                    }

                    a {
                        font-size: $font-size-base;
                        font-weight: $dropdown-list__label-font-weight;
                        text-transform: uppercase;
                        text-decoration: none;
                        @include font-padding(
                            $dropdown-list__label-font-size,
                            $dropdown-list__label-height,
                            $dropdown-list__label-padding-right,
                            $dropdown-list__label-padding-left
                        );

                        @include mq($screen-m) {
                            font-size: $font-size-large;
                        }

                        @include mq($screen-l) {
                            padding: 0;
                        }

                        &:hover,
                        &:focus {
                            @include mq($screen-l) {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &--wrapper {
                    border-bottom: none;

                    > .dropdown-list__content > .footer__links-list {
                        padding: 0;
                    }

                    .footer__links-list {
                        @include mq($screen-m) {
                            display: block;
                        }

                        @include mq($screen-l) {
                            padding: 0;
                            display: flex;
                        }
                    }
                }
            }

            &__list {
                flex-flow: row wrap;
            }
        }
    }

    &__social-handler {
        @include mq($screen-m) {
            margin: $footer__social-handler-margin\@medium;
        }

        @include mq($screen-l) {
            margin: $footer__social-handler-margin\@large;
        }

        &:before {
            display: none;
        }
    }

    &__social-icon {
        fill: $color-primary;
    }
    &__social-list {
        .button--icon-border {
            background-color: transparent;
            border: $border-width-base $border-style-base $color-primary;

            &:hover,
            &:focus {
                .icon {
                    fill: $gray-light;
                }
            }

            .icon {
                fill: $color-primary;
            }
        }
    }

    &__social-list-title {
        @include mq($screen-m) {
            font-size: $footer__social-list-title-font-size\@medium;
        }
    }

    &__payments-list {
        justify-content: flex-start;


        .list__item,
        .list__item:first-of-type,
        .list__item:last-of-type {
            padding: 0 $spacer $spacer--medium 0;

            @include mq($screen-l) {
                padding: 0 $spacer 0 0;
            }
        }
    }

    &__copyright {
        text-align: left;
        width: 100%;

        @include mq($screen-m) {
            width: auto;
            @include font-padding($font-size-medium, 48px);
        }
    }

    &__bottom-bar-handler {
        padding: $spacer--medium $spacer--semi-medium 0;

        @include mq($screen-m) {
            padding: $spacer--medium 40px 0;
        }

        @include mq($screen-l) {
            padding: $spacer--medium 0 0;
        }
    }

    &__footer-message {
        border-top: $border-width-base $border-style-base $gray-dark;
        color: $gray;
        order: 3;
        padding: $spacer--semi-medium 0;
        margin: 0 $spacer--semi-medium;
        font-size: $font-size-small;
        letter-spacing: $font-letter-spacing;

        @include mq($screen-m) {
            margin: 0 40px;
        }

        @include mq($screen-l) {
            margin: 0;
        }

        p {
            color: $gray;
        }
    }

    .newsletter {
        @include mq($screen-m) {
            padding: $spacer--extra-large 0 $spacer--second-extra-large;
        }

        @include mq($screen-l) {
            padding-bottom: 0;
            border-right: $border-base;
            padding: $spacer--semi-medium $spacer--extra-large 0 0;
        }

        @include mq($screen-xl) {
            border-right: none;
            padding: $spacer--semi-medium $spacer--large 0 0;
        }

        &__subtitle {
            margin-bottom: $spacer--semi-medium;

            @include mq($screen-m) {
                margin-bottom: 0;
            }
        }

        &__controls {
            margin: 0 0 $spacer--extra-small;

            @include mq($screen-xl) {
                max-width: none;
            }

            &__agreements {
                max-width: none;
            }
        }

        &__button {
            padding: 0 $spacer--extra-small;

            @include mq($screen-m) {
                padding: 0 $spacer;
                min-width: 128px;
            }
        }

        &__heading {
            @include mq($screen-m) {
                max-width: 250px;
            }

            @include mq($screen-xl) {
                margin: 0 $spacer--large 0 0;
            }
        }
    }

    .input.gdpr {
        margin-bottom: 0;
    }

    .category-dropdown {
        padding-bottom: 0;
        border-bottom: $footer__links-dropdown-list-item-border;

        @include mq($screen-l) {
            border-bottom: none;
        }
    }
}
