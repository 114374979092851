
.fotorama {
    &--fullscreen {
        .fotorama__stage {
            padding-left: 48px;
        }

        .fotorama__nav-wrap {
            &--vertical,
            &--horizontal {
                .fotorama__nav__frame--thumb {
                    @include mq($screen-m) {
                        margin: 0 $spacer 0 0;
                    }

                    @include mq($screen-xl) {
                        margin: 0 0 $spacer--medium 0;
                    }
                }
            }

            .fotorama__nav--thumbs {
                min-width: 110px;
            }
        }
    }

    .fotorama__stage {
        @include mq($screen-l) {
            width: 100% !important; // sass-lint:disable-line no-important
            margin: 0;
        }
    }

    .fotorama__wrap {
        @include mq($screen-xl) {
            padding-left: $spacer--medium;
        }
    }

    .fotorama__nav-wrap {
        @include mq($screen-m) {
            margin-top: $spacer--semi-medium;
        }
        @include mq($screen-xl) {
            margin-top: 0;
        }
    }
}
