.cms-page {
    &__text {
        line-height: 2;
        font-size: $font-size-base;
        margin: 0 auto $spacer--second-extra-large;
        text-align: center;
        max-width: $cms-page__text-max-width;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }

        @include mq($screen-l) {
            max-width: $cms-page__text-max-width\@large;
        }
    }

    &__button {
        width: 100%;

        @include mq($screen-s) {
            width: 320px;
        }
    }

    &__category {
        font-size: $font-size-medium;
        line-height: $font-line-height;
        margin-bottom: $spacer;
    }

    &__blog-title {
        font-size: $font-size-extra-large;
        line-height: 1.33;
    }

    &__header-box {
        margin: 0 $spacer;

        @include mq($screen-m) {
            margin: 0;
            background: $white;
            position: absolute;
            bottom: 24px;
            left: 24px;
            padding: $spacer--large $spacer--semi-large;
            max-width: $cms-page__header-box-max-width\@medium;
        }
    }

    &__header-box-description {
        margin-bottom: 0;
        line-height: 2;

        @include mq($screen-m) {
            line-height: $font-line-height;
        }
    }


    &__header-box-wrapper {
        position: relative;
        margin-bottom: $cms-page__header-box-wrapper-margin;

        @include mq($screen-m) {
            margin-bottom: $spacer--semi-medium;
        }

        picture {
            margin-bottom: $spacer--semi-large;

            @include mq($screen-m) {
                margin-bottom: 0;
            }
        }
    }

    &__blocks {
        margin: 0 auto 144px;

        @include mq($screen-m) {
            display: flex;
            flex-wrap: wrap;
            max-width: $cms-page__blocks-max-width\@medium;
        }

        @include mq($screen-l) {
            max-width: none;
        }
    }


    &__block {
        padding: 0 0 $spacer--medium;

        @include mq($screen-m) {
            padding: 0 $spacer $spacer--semi-medium;
            flex: 0 0 50%;
        }

        @include mq($screen-l) {
            padding-bottom: 0;
            flex: 0 0 25%;
        }

        &:first-of-type {
            @include mq($screen-l) {
                padding-left: 0;
            }
        }

        &:last-of-type {
            @include mq($screen-m) {
                padding-bottom: 0;
            }

            @include mq($screen-l) {
                padding-right: 0;
            }
        }

        &:nth-last-of-type(2) {
            @include mq($screen-m) {
                padding-bottom: 0;
            }
        }
    }

    &__block-inner {
        background-color: $gray-light;
        color: $color-primary;
        min-height: 192px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        font-size: $font-size-extra-large;
        font-weight: $font-weight-bold;
        font-family: $font-family-sans-serif--secondary;
        padding: $spacer--semi-medium $spacer--medium;
        cursor: pointer;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover,
        &:focus {
            background-color: $color-tertiary;
            text-decoration: none;
            color: $white;

            svg {
                fill: $white;
            }
        }

        &--secondary {
            justify-content: flex-end;
            padding: $spacer--medium;
            height: 100%;
            transition: $transition-base;

            @include mq($screen-m) {
                padding: $spacer--extra-large $spacer--semi-large;
            }

            @include mq($screen-l) {
                padding: $spacer--extra-large $spacer--medium 34px 21px;
            }

            .cms-page__block-label {
                align-items: flex-end;
                text-transform: uppercase;

                @include mq($screen-m) {
                    font-size: $font-size-super-extra-large;
                }
            }
        }
    }

    &__block-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__content {
        .blog-posts__item {
            .lazyload-wrapper {
                overflow: hidden;

                @include mq($screen-l) {
                    max-height: 240px;
                }

                @include mq($screen-xl) {
                    max-height: 280px;
                }

                @include mq($screen-xxl) {
                    max-height: 340px;
                }
            }
        }
    }
}
