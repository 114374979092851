.guide {
    display: flex;
    justify-content: center;

    &__step,
    &__step-content {
        flex-direction: column;
        opacity: 0;
        transition: $transition-fade;

        &:not(.guide__step--active):not(.guide__step-content--active) {
            @include visually-hidden();
        }

        &--active {
            opacity: 1;
        }
    }

    &__indicator-list {
        display: flex;
        justify-content: center;
        margin: $spacer--extra-large 0;
        padding: 0;
        list-style: none;

        @include mq($screen-m) {
            margin: 64px 0;
        }

    }

    &__indicator-item {
        width: 56px;
        height: 4px;
        background: $gray-light;

        @include mq($screen-m) {
            width: 96px;
        }

        &--active {
            background: #317195;
        }

        &:not(:last-child) {
            margin: 0 $spacer--medium 0 0;
        }
    }

    &__text {
        text-align: center;

        &--primary {
            max-width: 544px;
            margin: 0 auto;
        }

        &--secondary {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
        }
    }

    &__button-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
    }

    &__button-list-wrapper {
        display: flex;
        justify-content: center;
        margin: $spacer--semi-medium 0 0;

        &--secondary {
            margin: 0;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 128px;
        height: 160px;
        margin: $spacer;
        border: 1px solid $gray-light;
        font-family: $font-family-sans-serif--secondary;
        font-size: $font-size-extra-large;
        font-weight: $font-weight-bold;
        background: $gray-light;
        color: $color-primary;
        cursor: pointer;
        transition: $transition-base;

        @include mq($screen-m) {
            align-items: flex-start;
            width: 298px;
            padding: $spacer--large $spacer--large $spacer--semi-medium;
        }

        @include mq($screen-xl) {
            width: 320px;
        }

        &--secondary {
            width: 288px;
            text-align: center;
            align-items: center;

            @include mq($screen-m) {
                width: 360px;
            }

            @include mq($screen-l) {
                width: 368px;
            }
        }

        &--link {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: $spacer--large $spacer--large $spacer--semi-medium;
            text-align: left;
        }

        &--link-secondary {
            justify-content: flex-start;
            height: 112px;

            @include mq($screen-l) {
                width: 298px;
            }

            .icon {
                width: 48px;
                height: 48px;
            }
        }

        .icon {
            fill: $color-primary;
        }

        &:hover,
        &.focus-visible {
            border-color: $color-tertiary;
            background: $color-tertiary;
            color: $white;
            text-decoration: none;

            .icon {
                fill: $white;
            }

            .guide__sports-icon {
                filter: brightness(0) invert(1);
            }
        }
    }

    &__icon-wrapper {
        margin: 0 $spacer--medium 0 0;
        width: 30%;
        background: transparent;

        @include mq($screen-m) {
            width: 25%;
        }
    }

    &__button-icon {
        width: 48px;
        height: 48px;
        margin: 0 0 $spacer--semi-medium;
    }

    &__button-name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        @include mq($screen-m) {
            justify-content: space-between;
        }

        .icon {
            display: none;

            @include mq($screen-m) {
                display: flex;
            }
        }
    }

    &__image-wrapper {
        position: relative;
        overflow: hidden;
        margin: 0 0 $spacer--semi-large;

        @include mq($screen-m) {
            margin: 0 0 75px;
            min-height: 328px;
        }
    }

    &__image-title {
        display: none;
        position: absolute;
        left: 56px;
        bottom: 40px;
        font-family: $font-family-sans-serif--secondary;
        font-size: $font-size-super-extra-large;
        font-weight: $font-weight-bold;
        background: $white;
        text-transform: uppercase;
        min-width: 280px;
        padding: $spacer--extra-large $spacer--semi-large $spacer--semi-medium;

        @include mq($screen-m) {
            display: flex;
        }
    }

    &__title {
        font-family: $font-family-sans-serif--secondary;
        font-size: $font-size-extra-large;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        margin: 0 0 $spacer--semi-large;

        @include mq($screen-m) {
            display: none;
        }
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__text-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &__back-button {
        position: absolute;
        left: 0;
        top: 4px;
        background: none;
        border: none;
        cursor: pointer;

        @include mq($screen-m) {
            top: 0;
        }

        .icon {
            fill: $color-primary;
        }
    }

    &__sports-icon {
        max-width: 48px;
        transition: $transition-base;
    }
}
