.bundle-option {
    &__qty {
        flex-direction: column;
        align-items: flex-start;
    }
    &__qty-input {
        height: 56px;
    }
    .radio__label {
        padding-bottom: $spacer--medium;
        border-bottom: $border-tertiary;
    }
    .price-notice {
        min-width: 100px;
        text-align: right;
    }
}
