.stamped {
    .stamped-starrating {
        display: flex;
    }

    .stamped-badge {
        display: flex;
        color: $gray-dark;
        font-size: $font-size-small;

        &[data-rating="0"],
        &[data-rating="0,0"],
        &[data-rating="0.0"] {
            display: none;
        }
    }

    .stamped-badge-starrating {
        margin: 0 $spacer 0 0 !important; // sass-lint:disable-line no-important
    }

    .stamped-badge-caption {
        display: flex;
        visibility: hidden;

        &[data-reviews][data-label][data-version="2"] {
            &:before,
            &:after {
                visibility: visible;
            }

            &:before {
                content: attr(data-rating);
            }

            &:after {
                content: '(' attr(data-reviews) ')' !important; // sass-lint:disable-line no-important
            }

            & > span  {
                display: none !important; // sass-lint:disable-line no-important
            }
        }
    }

    .stamped-product-reviews-badge {
        .stamped-badge {
            display: flex;
            font-size: $font-size-base;
        }

        .stamped-badge-caption {
            margin: 0 $spacer--semi-medium 0 0;

            &[data-reviews][data-label][data-version="2"] {
                &:before {
                    content: attr(data-rating) ' / ';
                }

                &:after {
                    content: attr(data-reviews) ' reviews' !important; // sass-lint:disable-line no-important
                    text-decoration: underline;
                    color: $color-primary;
                }

                &:hover,
                &.focus-visible {
                    &:after {
                        color: $color-info;
                    }
                }
            }
        }
    }

    .stamped-form-review-recommend {
        display: flex;
        flex-direction: column;

        .stamped-form-label {
            display: flex;
            align-items: center;
            gap: $spacer; //sass-lint:disable-line no-misspelled-properties
        }
    }

    &__summary {
        &:hover,
        &.focus-visible {
            text-decoration: none;
        }
    }
}
