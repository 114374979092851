.blog-posts {
    &__items {
        @include mq($screen-l) {
            display: flex;
            justify-content: center;
        }
    }

    &__item {
        padding: 0 $spacer;
        margin-bottom: $spacer--extra-large;
        display: block;

        @include mq($screen-l) {
            flex: 1;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $color-primary;
        }
    }

    &__item-category {
        margin: $spacer--medium 0 $spacer;
        text-transform: uppercase;

        @include mq($screen-m) {
            margin: $spacer--medium $spacer--semi-medium $spacer;
            font-size: $font-size-medium;
        }

        @include mq($screen-l) {
            margin: $spacer--medium 0 $spacer;
        }

        @include mq($screen-xl) {
            margin: $spacer--medium $spacer--semi-medium $spacer;
        }
    }

    &__item-title {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        line-height: 1.3;

        @include mq($screen-m) {
            margin: 0 $spacer--semi-medium;
            font-size: $font-size-extra-large;
        }

        @include mq($screen-l) {
            margin: 0;
        }

        @include mq($screen-xl) {
            margin: 0 $spacer--semi-medium;
        }
    }
}
