.download-items {
    &__list {
        position: relative;
        padding: 0;
        list-style: none;
    }
    &__list-item {
        font-size: $font-size-medium;
        @include font-padding($font-size-medium, 48px, 0, $spacer--extra-large);
    }
    &__icon {
        position: absolute;
        top: 12px;
        left: 12px;
    }
    &__link {
        text-decoration: underline;
    }
}
