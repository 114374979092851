.content-list {
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &__item {
        position: relative;
        line-height: $font-line-height;
        font-size: $font-size-medium;
        margin-bottom: $spacer;
        @include font-padding($font-size-medium, 48px, 0, $spacer--semi-medium);

        &:before {
            position: absolute;
            display: block;
            content: '';
            top: 20px;
            left: 0;
            width: $spacer;
            height: $spacer;
            background-color: $color-primary;
        }
    }
}
