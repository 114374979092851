.loader {
    &--relative {
        position: relative;
        height: auto;
        .loader__icon {
            width: 12px;
            height: 12px;
        }
    }
    &--small {
        width: 12px;
    }
}
