.product-grid-item {
    &__stock-status {
        display: flex;
        align-items: center;
    }

    &__name-link {
        font-weight: $font-weight-lightmedium;
    }
}
