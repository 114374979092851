// Colors
$orange                            : #ff6c10;
$green                             : #0e8a00;
$green-blue                        : #0194ab;

// Grayscale colors
$gray-dark                         : #66788a;
$gray                              : #8a9da9;
$gray-light                        : #eaf1f6;

// Semantic color scheme
$color-primary                     : #022135;
$color-tertiary                    : #007398;

// Typography
$font-family-sans-serif--secondary : 'RobotoCondensed', serif;
$font-family-secondary             : $font-family-sans-serif--secondary;

$font-line-height                  : 1.5;
$font-weight-lightmedium           : 500;
$font-weight-medium                : 600;

$font-letter-spacing-s             : 0.5px;
$font-letter-spacing               : 1px;
$font-letter-spacing-m             : 1.17px;

// Border
$border-color-secondary            : $gray-dark;
$border-color-tertiary             : $gray-light;
$border-width-tertiary             : 1px;
$border-style-tertiary             : solid;
$border-tertiary                   : $border-width-tertiary $border-style-tertiary $border-color-tertiary;

// Components
$form-elements-border-color        : $gray;

// Spacer
$spacer--second-extra-large        : 64px;
$spacer--third-extra-large         : 72px;
