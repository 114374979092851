.product-view {
    &__wrapper {
        @include mq($screen-m) {
            grid-template-columns: $product-view__details-width-and-offset\@medium $product-view__details-width-and-offset\@medium;
            column-gap: $spacer--medium;
        }
        @include mq($screen-l) {
            grid-template-columns: $product-view__details-width-and-offset\@large $product-view__details-width\@large;
            margin: $product-view__wrapper-margin\@large;
            max-width: 1216px;
            column-gap: $spacer--extra-large;
        }
    }

    &__gallery {
        @include mq($screen-m) {
            max-width: $product-view__gallery-horizontal-max-width;
            overflow: hidden;
        }
        @include mq($screen-xl) {
            max-width: $product-view__gallery-vertical-max-width;
        }
    }

    &__description,
    &__details {
        @include mq($screen-m) {
            width: 100%;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
    }

    &__title {
        order: 2;
        line-height: 1.778;
        letter-spacing: $font-letter-spacing;
        @include mq($screen-m) {
            line-height: $font-line-height;
        }
        &.heading--page {
            @include mq($screen-m) {
                margin: 0;
            }
        }
    }

    &__manufacturer {
        order: 1;
        text-decoration: underline;
        font-weight: $font-weight-bold;
        @include font-padding($font-size-base, $spacer--extra-large);
        @include mq($screen-m) {
            @include font-padding($font-size-base, $spacer--semi-medium);
        }
    }

    &__sku {
        order: 3;
        @include font-padding($font-size-base, $spacer--semi-medium);
    }

    &__rating {
        @include font-padding($font-size-base, $spacer--extra-large);
    }

    &__marketing {
        min-width: $product-view__marketing-min-height;
    }

    &__price {
        .price__value {
            display: block;
            line-height: 1.336;
        }
    }

    &__short-description {
        font-size: $product-view__short-description-font-size;
        line-height: $product-view__short-description-line-height;
    }

    &__short-description-heading {
        @include font-padding($font-size-base, $spacer--semi-medium);
    }

    &__form {
        @include mq($screen-m) {
            padding: $product-view__form-padding\@medium;
        }
    }

    &__addtowishlist {
        background-color: $product-view__button-bg;
    }

    &__brief {
        @include mq($screen-m) {
            margin: $product-view__brief-margin\@medium;
        }
    }
    .select2-container {
        .select2-selection--single {
            height: $product-view__select-option-height;

            .select2-selection__rendered {
                line-height: $product-view__select-option-height;
            }

            .select2-selection__arrow {
                height: $product-view__select-option-height;
            }
        }

        .select2-results {
            &__option {
                height: $product-view__select-option-height;
                line-height: $product-view__select-option-height;
            }
        }
    }

    .select2-container--default .select2-selection--single {
        .select2-selection__arrow {
            right: 16px;
        }
    }
    .select2-container .select2-selection--single .select2-selection__rendered {
        padding: 0 56px 0 $spacer--medium;
    }

    &__tabs-sticky {
        .tab__title--sticky {
            text-align: center;
            padding: $spacer;
            min-height: 48px;
        }
    }

    &__tab {
        .content-block__column {
            img {
                width: auto;
            }
        }
    }

    &__brand {
        margin-bottom: $spacer--medium;
        @include mq($screen-l) {
            padding: 0;
        }
    }
    &__brand-row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: $product-view__brand-padding;
        @include mq($screen-m) {
            padding: $product-view__brand-padding\@medium;
        }
        @include mq($screen-l) {
            flex-direction: row;
            flex-wrap: wrap;
            padding: $product-view__brand-padding\@large;
        }
    }

    &__brand-title {
        font-size: $font-size-extra-large;
        line-height: 2;
        @include mq($screen-m) {
            font-size: $font-size-super-extra-large;
        }
    }

    &__brand-media {
        order: 1;
        margin-bottom: $spacer--extra-large;
        @include mq($screen-l) {
            order: 2;
            margin-bottom: 0;
        }
    }

    &__brand-main {
        order: 2;
        @include mq($screen-l) {
            order: 1;
        }
    }

    &__sold {
        margin: $spacer--semi-large 0 $spacer--semi-medium;
        color: $color-primary;
        @include font-padding($font-size-base, 24px);
        @include mq($screen-m) {
            margin-top: auto;
            text-align: right;
        }
    }

    &__size-guide {
        min-height: auto;
        padding: 0;
        margin: 0 0 $spacer--medium;
        font-size: $font-size-base;
        text-decoration: none;
    }

    &__sizing-chart {
        table {
            @extend .table;

            margin: 0 auto;
            width: auto !important; // sass-lint:disable no-important
            height: auto !important; // sass-lint:disable no-important
        }

        img {
            width: auto;
        }
    }

    // grouped product, overwrite default table styles
    &__grouped-table {
        th,
        td {
            &.product-view__grouped-product-name-cell {
                display: block;
                padding: 0;
                @include mq($screen-m) {
                    padding: $spacer--medium 0;
                    display: table-cell;
                    padding-right: $spacer--semi-medium;
                }
            }
            &.product-view__grouped-product-qty-td input {
                margin-bottom: 0;
            }
        }

        tr {
            &:last-child {
                border-bottom: $border-width-base $border-style-base $gray-light;
            }
        }

        .quantity-update {
            flex-wrap: nowrap;
        }
    }

    &__grouped-qty {
        .input__field {
            height: $product-view__qty-height;
        }
    }

    &__cms-block {
        @include mq($screen-m) {
            max-width: 497px;
            margin: 72px auto 0;
        }

        @include mq($screen-l) {
            display: flex;
            align-items: flex-start;
            flex-direction: row-reverse;
            max-width: 100%;
            margin: 0;
        }

        @include mq($screen-xl) {
            margin: 0 112px;
        }
    }

    &__cms-block-image {
        display: flex;
        justify-content: center;
        flex: 1;
        margin: 0 0 $spacer--extra-large;

        img {
            width: auto;
        }
    }

    &__cms-block-content {
        flex: 1;

        @include mq($screen-l) {
            margin: 0 $spacer--medium 0 0;
        }

        @include mq($screen-xl) {
            margin: 0 64px 0 0;
        }
    }

    &__cms-block-title {
        margin: 0 0 $spacer--large;
        font-size: $font-size-extra-large;

        @include mq($screen-m) {
            font-size: $font-size-super-extra-large;
        }
    }

    &__cms-block-description {
        p {
            margin: 0 0 $spacer--large;
        }
    }

    &__details-content {
        & > p {
            &:first-of-type {
                margin: 0 0 $spacer--medium;
            }
        }
    }

    &__grouped-product-image-wrapper {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
        }
    }

    &__grouped-product-image {
        width: 120px;
        padding-right: $spacer;
    }
}

.catalog-product-view {
    .select2-results__option {
        &[aria-disabled=true] {
            &:after {
                content: " (Out of Stock)";
            }
        }
    }
}
